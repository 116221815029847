<template>
  <CRow>
    <CCol sm="12">
      <CCard class="p-5">
        <CRow>
          <CCol sm="3">
            <h4>Reading Generate </h4>
          </CCol>
          <CCol sm="9">
            <form v-on:submit.prevent="generateReading()">
              <CRow>
                <CCol sm="3" class="pr-0">
                  <el-date-picker
                      :picker-options="pickerOptions"
                      required
                      class="w-100"
                      v-model="params.month"
                      type="month"
                      placeholder="Select Month"
                      :format="$monthPickerFormat"
                      value-format="yyyy-MM"
                  />
                </CCol>
                <CCol sm="2">
                  <button type="submit" class="btn btn-success w-100">Generate</button>
                </CCol>

                <CCol sm="7">
                  <router-link class="btn btn-primary float-right" :to="{name:'ReadingList'}">
                    Generated Reading List
                  </router-link>
                </CCol>
              </CRow>
            </form>
          </CCol>
        </CRow>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";
import moment from "moment/moment";

export default {
  name: "ReadingGenerate",
  data: () => {
    return {
      params: {
        month: moment().clone().subtract(1, 'months').format('YYYY-MM'),
      },
      last_generate: moment().clone().subtract(1, 'months').format('YYYY-MM'),

      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Reading Generate",
        path: "",
        isActive: true
      }]
    };
  },
  mounted() {
    this.fetchGenerateMonth()
  },
  computed: {
    pickerOptions() {
      return {
        disabledDate: this.disabledDate.bind(this),
      };
    },
  },
  methods: {
    disabledDate(time) {
      const currentYear = new Date().getFullYear();
      const lastGenerateYear = new Date(this.last_generate).getFullYear();
      const currentMonth = new Date().getMonth();
      const lastGenerateMonth = new Date(this.last_generate).getMonth();

      return !(
          (time.getFullYear() === lastGenerateYear && time.getMonth() > lastGenerateMonth) ||
          (time.getFullYear() === currentYear && time.getMonth() < currentMonth)
      );
    },
    fetchGenerateMonth() {
      axios.get(`/admin/fetch-generate-month/reading`).then(({data}) => {
        if (!data.status){
          this.last_generate = moment().clone().subtract(5, 'months').format('YYYY-MM');
          return;
        }
        this.last_generate = data.data.generate_year + '-' + data.data.generate_month
        const lastGenerateDate = moment(this.last_generate + '-01');
        this.params.month = lastGenerateDate.clone().add(1, 'month').format('YYYY-MM');
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.$store.dispatch("Settings/loading", false);
      })
    },
    generateReading() {
      const formattedMonth = moment(this.params.month).format('MMMM-YYYY')
      this.$confirm({
        message: `Are you want to generate ${formattedMonth} month reading?`,
        button: {
          no: "No",
          yes: "Yes, Generate",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("Settings/loading", true);
            axios.post(`/admin/readings`, this.params).then(({data}) => {
              if (data.status == 'error') {
                return this.$toastr.e(
                    data.status,
                    data.message,
                    data.title
                );
              }
              this.$toastr.s(
                  data.status,
                  data.message,
                  data.title
              );
              this.$router.push({name: 'ReadingList', query: {month: this.params.month}})

            }).catch((err) => {
              console.log(err);
            }).finally(() => {
              this.$store.dispatch("Settings/loading", false);
            })
          }
        },
      });
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>
